import React from "react"

import LocationPreview from "../../components/location-preview/location-preview"
import MunzingLocationMap from "../../components/map/munzingLocationMap"
import OverviewGrid from "../../components/overview-grid/overview-grid"
import SEO from "../../components/seo"
import Tile from "../../components/tile/tile"
import SideNavView from "../../components/views/side-nav-view/side-nav-view"
import useMunzingLocations from "../../hooks/useMunzingLocation"
import { useTranslation } from "../../i18n/translate"

const MunzingWorldwide = ({ location }) => {
  const { t } = useTranslation()
  const locations = useMunzingLocations(t)

  return (
    <>
      <SEO
        pathName={location.pathname}
        titleTranslatedString={t("MUNZING_GROUP-MUNZING_WORLDWIDE-META_TITLE")}
        descriptionTranslatedString={t(
          "MUNZING_GROUP-MUNZING_WORLDWIDE-META_DESCRIPTION",
        )}
      />
      <SideNavView
        titleTranslatedString={t("MUNZING_GROUP-MUNZING_WORLDWIDE-META_TITLE")}
        pathName={location.pathname}
      >
        <div className="_fp-grid _fp-grid--gap">
          <div className="_fp-col-12">
            <Tile
              titleTranslatedString={t(
                "MUNZING_GROUP-MUNZING_WORLDWIDE-LOCATIONS-OUR_LOCATIONS",
              )}
            >
              <MunzingLocationMap locations={locations} useCustomMap={true} />
            </Tile>
          </div>

          <div className="_fp-col-12">
            <OverviewGrid>
              {locations.map((location, index) => {
                return <LocationPreview key={index} location={location} />
              })}
            </OverviewGrid>
          </div>
        </div>
      </SideNavView>
    </>
  )
}

export default MunzingWorldwide

import { map } from "lodash"

import { toUrl } from "../util/urls"
import { ContactModel } from "./contactModel"
import { GoogleMapsLocationModel } from "./googleMapsLocationModel"

export class MunzingLocationModel {
  constructor(a) {
    this.id = a.strapiId || a.id
    this.name = a.name

    if (a.nav_item) {
      this.url = a.nav_item.url
      this.order = a.nav_item.order
    } else {
      this.url = a.url
      this.order = a.order
    }

    if (a.navUid) {
      this.navUid = a.navUid.uid
    } else {
      this.navUid = a.uid
    }
    this.brandIds = map(a.brands || [], "id")
    this.description = a.description || {}
    this.short_description = a.short_description || {}
    this.locationName = a.location_name || a.locationName
    this.location = new GoogleMapsLocationModel(a.location)
    this.contacts = a.contacts
      ? a.contacts.map((contact) => new ContactModel(contact))
      : []
    this.image_resources = a.image_resources || []
    this.matterportId = a.matterport_id
  }

  getPageUrl() {
    return toUrl(`/munzing-group/munzing-worldwide/${this.url}/`)
  }
}
